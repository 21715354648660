import React from "react"
import FormComponent from "./form-component"

export default function ContactForm(props) {
  return (
    <div className="shadow-custom-out">
      <div className="container max-w-screen-xl pb-8">
        <div className="flex flex-col md:flex-row md:space-x-6">
          {/* Info Card */}
          <div className="w-full md:w-1/3 pb-4">
            <div
              className={`rounded-b-[15px] p-[30px] text-left ${props.shadow}`}
              style={{
                backgroundColor: props.backgroundColor,
              }}
            >
              <h3
                className="text-[1.75rem] text-balance mb-0"
                style={{
                  color: props.fontShadowColor,
                }}
              >
                {props.title}
              </h3>
            </div>
          </div>
          {/* Form */}
          <div className="w-full md:w-2/3 py-4">
            <FormComponent formType={props.formType}/>
          </div>
        </div>
      </div>
    </div>
  )
}
