import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/bodycomponents/contact-form-no-body"
import FAQ from "../../components/faq-components/faq"
import { appraisalFaqItems } from "../../utils/data/faq"
import TopHeroComponent from "../../components/herocomponents/topherocomponent"
import R_BLUE_BG from "../../images/background-images/bg-royal-blue.svg"
import M_GRAY_BG from "../../images/background-images/bg-medium-gray.svg"
import { colors } from "../../utils/colors"
import CardComponent from "../../components/bodycomponents/card-component"
import { Link } from "gatsby"

import AMEA_LOGO from "../../images/services/appraisals/AMEA-Logo_BW.png"
import MDNA_LOGO from "../../images/services/appraisals/MDNA-Logo_BW.jpg"

const AppraisalsPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Laboratory Equipment Appraiser & Appraisal Services`}
          description={`Surplus Solutions' certified appraisers expertly evaluate anything from individual lab equipment to entire facilities. Contact us now to learn more!`}
        />
        <TopHeroComponent
          backgroundImage={R_BLUE_BG}
          backgroundColor={colors.blue}
          shadowColor="shadow-royalBlue-bottom"
          fontShadowColor={colors.royalBlue}
          pageTitle="Appraisal Services"
          title="Accurate Appraisal Services by AMEA-Certified Experts"
          body={
            <p className="pt-4">
              Lay the foundation for a successful equipment sale with Surplus
              Solutions’ AMEA-certified appraisal services. We offer tailored
              options to maximize returns, expedite sales, or estimate
              replacement costs—perfectly aligned with your goals and timeline.
            </p>
          }
        />
        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl py-14">
            <div className="">
              <h2 className="pb-2 text-left">
                Types of Appraisals
              </h2>
              <h3>Fair Market Value</h3>
              <p className="pb-6">
                If you’re not in a hurry to sell and want to maximize returns,
                the Fair Market Value appraisal—our standard report—can be your
                best option. This laboratory equipment evaluation provides a
                professional in-person estimate of your equipment’s market
                value, reflecting how much prospective buyers are willing to pay
                for it in an open market.
              </p>
              <h3>Orderly Liquidation Value</h3>
              <p className="pb-6">
                Surplus Solutions’ Orderly Liquidation Value appraisal gives you
                an onsite equipment evaluation report customized to your
                liquidation timeline, taking into account any additional factors
                that could affect asset pricing. So whether you have time or
                need to liquidate quickly, our certified equipment appraisers
                use their expertise to produce an accurate, detailed report
                tailored to your unique situation.
              </p>
              <h3>Forced Liquidation Value</h3>
              <p className="pb-6">
                A Forced Liquidation Value report is the perfect choice for
                those facing strict time constraints and looking to sell used
                equipment—or an entire facility—swiftly. This appraisal,
                conducted in your lab, provides a reliable estimate of your
                equipment’s most-probable price when sold at a properly
                conducted, well-advertised public auction that gives you access
                to a wide pool of prospective buyers.
              </p>
              <h3>New Replacement Cost Value</h3>
              <p className="pb-6">
                Our New Replacement Cost Value report takes the guesswork out of
                estimating the cost of replacing existing assets in today’s
                market. Our team handles everything, examining all{" "}
                <Link to="/buy-equipment/manufacturers">manufacturers</Link>{" "}
                that make equipment comparable to what you’re replacing to
                generate an accurate replacement cost estimate. Plus, we can
                scour our extensive used equipment channels to find high-quality
                replacements that perfectly suit your needs and demands.
              </p>
              <h3>Desktop Opinion</h3>
              <p className="">
                A Desktop Opinion offers a fast, professional estimate of your
                used equipment’s likely value, based on information you provide
                rather than onsite inspection. While not a formal appraisal, and
                not suited for legal or credit matters, a Desktop Opinion can be
                a great way to quickly identify the best sales channel for your
                surplus assets.
              </p>
            </div>
          </div>
        </div>
        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl py-10">
            <h2 className="text-center pb-6">Why Choose Surplus Solutions?</h2>
            <div className="grid gap-9 lg:grid-cols-3">
              <CardComponent
                title="Expertise"
                body={
                  <p>
                    With over 25 years of industry experience, Surplus Solutions
                    combines an extensive sales database with the expertise of
                    certified appraisers to deliver highly accurate appraisals
                    aligned with current market trends.
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
              <CardComponent
                title="Efficiency"
                body={
                  <p>
                    Simply put, we handle all the work for you. The Surplus
                    Solutions team of experts streamlines the entire appraisal
                    process, saving you the time and hassle of trying to collect
                    data for accurate appraisals on your own.
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
              <CardComponent
                title="Best Practices"
                body={
                  <p>
                    Thanks to continuous education and biennial AMEA reviews,
                    you can count on our appraisers to deliver trustworthy,
                    up-to-date equipment valuations that comply with industry
                    standards for legal documentation.
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
            </div>
          </div>
        </div>
        <FAQ
          title="Appraisal FAQs"
          items={appraisalFaqItems}
          backgroundColor={colors.lightGray}
          backgroundImage={M_GRAY_BG}
          shadowColor="shadow-mediumGray-top"
        />
        <ContactForm
          title="Get your equipment appraised by certified experts today!"
          backgroundColor={colors.containerGray}
          fontShadowColor={colors.royalBlue}
          shadow="shadow-royalBlue-bottom"
          formType="Contact"
        />
        <div className="container max-w-screen-xl pb-14">
          <div className="w-full flex flex-row justify-between items-center px-0 md:px-10 gap-10">
            <div className="py-2">
              <a href="https://www.amea.org/" target="_blank" rel="noreferrer">
                <img
                  src={AMEA_LOGO}
                  alt="AMEA Logo"
                  className="h-24 object-contain"
                />
              </a>
            </div>
            <div className="py-2">
              <a href="https://mdna.org/" target="_blank" rel="noreferrer">
                <img
                  src={MDNA_LOGO}
                  alt="MDNA Logo"
                  className="h-24 object-contain"
                />
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default AppraisalsPage
